import React, { useState, useEffect } from "react";

import ShowAdminTable from "../components/showAdminTable";

const ParamsPage = () => {
  return (
    <div className="w-full flex-1 pt-10">
      <ShowAdminTable />
    </div>
  );
};

export default ParamsPage;
