import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  // apiKey: "AIzaSyCo-WDvFtAc6tzSYqoOAakCzB2isXkQkn0",
  // authDomain: "girouette-3078c.firebaseapp.com",
  // projectId: "girouette-3078c",
  // storageBucket: "girouette-3078c.appspot.com",
  // messagingSenderId: "1004577227213",
  // appId: "1:1004577227213:web:ede303dd47a24fa8244484",
  // measurementId: "G-2C0JZJ8N4B"

  apiKey: "AIzaSyB6xJuJG9zvaK8yS9ExgJBkquDloinsKzc",
  authDomain: "pc-api-8055218289391536178-645.firebaseapp.com",
  projectId: "pc-api-8055218289391536178-645",
  storageBucket: "pc-api-8055218289391536178-645.appspot.com",
  messagingSenderId: "1050499729617",
  appId: "1:1050499729617:web:64a95e90142df03927ddf0",
  measurementId: "G-9QQJ30RK8W",
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const messaging = getMessaging(app);

export default app;

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyB6xJuJG9zvaK8yS9ExgJBkquDloinsKzc",
//   authDomain: "pc-api-8055218289391536178-645.firebaseapp.com",
//   projectId: "pc-api-8055218289391536178-645",
//   storageBucket: "pc-api-8055218289391536178-645.appspot.com",
//   messagingSenderId: "1050499729617",
//   appId: "1:1050499729617:web:64a95e90142df03927ddf0",
//   measurementId: "G-9QQJ30RK8W",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
