import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  NavLink,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import { useContext, useEffect, useState } from "react";
import LoginPage from "./pages/LoginPage";
import CitiesPage from "./pages/citiesPage";
import PointsPage from "./pages/pointsPage";
import VisitsPage from "./pages/visitsPage";
import CategoryPage from "./pages/categoryPage";
import AuthContext from "./context/AuthContext/AuthContext";
import Loader from "./components/loader";
import NotificationPage from "./pages/notificationPage";
import AdminPage from "./pages/adminPage";
import ShowAdminPage from "./pages/showAdminPage";
import GeneralePage from "./pages/generalePage";
import UsersPage from "./pages/usersPage";
import ParamsPage from "./pages/paramsPage";
import AbonnementPage from "./pages/abonnmentPage";
import SideBareContext from "./context/SideBareContext/SideBareContext";
import ShowUser from "./pages/ShowUser";
import ForgotPasswordPage from "./pages/forgotPasswordPage";



function App() {
  const user = useContext(AuthContext);
  const [flag, setFlag] = useState(true);
  const { setActiveRoute } = useContext(SideBareContext);

  useEffect(() => {
    // document.head.

    const currentPath = window.location.pathname;
    setActiveRoute(currentPath);

    setTimeout(() => {
      setFlag(false);
    }, 2000);
  }, [user]);

  return (
    <div className=" w-full overflow-x-hidden">

      <Routes>
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route
          path="/"
          element={
            user !== null ? <HomePage /> : flag ? <Loader /> : <LoginPage />
          }
        >
          <Route path="/" element={<GeneralePage />} />
          <Route path="/cities" element={<CitiesPage />} />
          <Route path="/points" element={<PointsPage />} />
          <Route path="/visits" element={<VisitsPage />} />
          <Route path="/themes" element={<CategoryPage />} />
          <Route path="/notifications" element={<NotificationPage />} />
          <Route path="/accounting" element={<UsersPage />} />
          <Route path="/accounting/:id" element={<ShowUser />} />
          <Route path="/admins" element={<AdminPage />} />
          <Route path="/admins/:id" element={<ShowAdminPage />} />
          <Route path="/params" element={<ParamsPage />} />
          <Route path="/abonnement" element={<AbonnementPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
        {/* <Route path="/login" element={<LoginPage />} /> */}
      </Routes>
    </div>
  );
}

export default App;
