import React, { useState, useContext } from "react";

import { registerPlugin } from "react-filepond";

import * as Yup from "yup";
import { useFormik } from "formik";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { uploadFile } from "../api/fileService";
import AlertContext from "../context/AlertContext/allertContext";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const UserEditForm = ({ setShowFormular, user, setUser, data, setData }) => {
  const { showAlert } = useContext(AlertContext);
  const [files] = useState([]);

  const [loading, setLoading] = useState(false);

  const error = "";

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: user ? user.name : "",
      email: user ? user.email : "",
      phone: user ? user.phone : "",
      // birthdate: user ? user.birthdate : "",
      birthday: user ? user.birthday : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter city name"),
      email: Yup.string().required("Please Enter your email"),
      phone: Yup.string().required("Please Enter your phone"),
      birthday: Yup.string().required("Please Enter your birthdate"),

      // birdthday: Yup.string().required("Please Enter your birthday"),
      // distance: Yup.number().positive().required("Please Enter visit distance"),
    }),
    onSubmit: (values) => {
      console.log("submitted");
      if (loading === false) {
        setLoading(true);
        console.log("values", values);
        console.log("user", user);
        console.log(user?"tru": "faled");
        if (user) {
          console.log("is Updatingx");


          // // data.name = values.name;
          // // data.email = values.email;
          // // data.phone = values.phone;
          // // data.birthday = values.birthday;
          // // if (files[0].filename !== undefined) {
          // //   data.image = await uploadFile(files[0], (progressEvent) => {});
          // //   console.log(data.image);
          // // }

          user.name = values.name;
          // data.address = values.address;
          user.email = values.email;

          user.phone = values.phone;

          user.birthday = values.birthday;

          // console.log("new name", user.name);
          console.log("pending user:", user);

          console.log("date", values.birthday);

          


          user.save().then((result) => {
            // console.log("result", result);
            showAlert("User a été mise à jour avec succès.");
          }).catch((error) => {
            console.log("error", error);
            showAlert("Erreur lors de la mise à jour de l'utilisateur.");
          });



        } else {
          try {
            // console.log("trying");

            showAlert("No user");
          } catch (e) {}
        }
        setShowFormular(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      action="#"
    >
      <div className="fixed overflow-hidden h-full z-30 top-0 w-full left-0">
        <div className="flex items-center justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center overflow-y-auto max-h-[calc(100%-4rem)]   bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  Update User
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setShowFormular(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <div className="mb-3">
                {/* {user !== undefined && (
                  <>
                    <label className="block font-bold text-sm" htmlFor="id">
                      id
                    </label>
                    <h1 className="w-full border-2 text-gray-500 outline-none border-gray-100 bg-gray-100 py-2 pl-4 mt-2 rounded-full">
                      {user.id}
                    </h1>
                  </>
                )} */}
              </div>

              <div className="flex flex-col b justify-center items-center p-5">
                <div className=" w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Nom <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="name"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                    placeholder="Entrer le nom"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    // invalid={
                    //   validation.touched.name && validation.errors.name ? true : false
                    // }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.name}
                    </p>
                  ) : null}
                </div>

                <div className=" w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="email"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                    placeholder="Entrer l'email"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    // invalid={
                    //   validation.touched.name && validation.errors.name ? true : false
                    // }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.email}
                    </p>
                  ) : null}
                </div>

                <div className=" w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Numero De Telephone <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="phone"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                    placeholder="Entrer le numero de telephone"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone || ""}
                    // invalid={
                    //   validation.touched.name && validation.errors.name ? true : false
                    // }
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.phone}
                    </p>
                  ) : null}
                </div>

                <div className=" w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Date De Naissance <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="birthday"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 px-2  rounded-full"
                    type="date"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.birthday}
                    // invalid={
                    //   validation.touched.name && validation.errors.name ? true : false
                    // }
                  />
                  {validation.touched.birthday && validation.errors.birthday ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.birthday}
                    </p>
                  ) : null}
                </div>
              </div>

              {/* <div className=" w-full">
                  <label
                    htmlFor="duration"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Distance <span className="text-red-500">*</span>
                  </label>
                  <div>
                    <div className="flex flex-row items-center mt-2 mb-3">
                      <input
                        name="distance"
                        className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                        placeholder="Entrer le durée"
                        type="number"
                        onChange={(e) => {
                          validation.handleChange(e);
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.distance}
                      />
                      <span className="px-3 font-bold text-orange-500">
                        (Km)
                      </span>
                    </div>{" "}
                    {validation.touched.distance &&
                    validation.errors.distance ? (
                      <p className="text-red-500 text-xs">
                        {validation.errors.distance}
                      </p>
                    ) : null}
                  </div>
                  {validation.touched.distance && validation.errors.distance ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.distance}
                    </p>
                  ) : null}
                </div> */}
            </div>

            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                onClick={() => {
                  setShowFormular(false);
                }}
                type="button"
                className="py-3 px-8 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
              >
                Annuler
              </button>
              <button
                color="success"
                disabled={error ? null : loading ? true : false}
                className="py-3 px-10 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                type="submit"
              >
                {error ? null : loading ? (
                  <h1 className="text-sm me-2"> loading... </h1>
                ) : user !== undefined ? (
                  "Mettre à jour"
                ) : (
                  "Créer"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UserEditForm;
