import Chart from "react-apexcharts";
import download from "../../assets/icons/generalePage/download.svg";
import dateIcon from "../../assets/icons/generalePage/calendar.svg";
import { Button } from "@material-tailwind/react";
import { useState } from "react";
import { useEffect } from "react";
import { DateRange } from "react-date-range";
import { CSVLink } from "react-csv";
import Payment from "../../models/payment";
import moment from "moment";
import LoadingSpinner from "../loadingSpinner";
import fr from "date-fns/locale/fr";

const ZERO = 0;

export default function SalesChart() {
  const [loading, setLoading] = useState(false);
  const [todayRevenue, setTodayRevenue] = useState(5000);
  const [improvements, setImprovements] = useState(0);
  const [visibleCalender, setVisibleCalender] = useState(false);
  // chart settings;
  const [options, setOptions] = useState({
    chart: {
      dropShadow: {
        enabled: true,
        blur: 10,
        color: "#2B83FF",
      },

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sort: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        width: "20px",
        borderRadius: 10,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "#23273d",
          fontSize: "20px",
          opacity: 0.5,
          fontFamily: "Poopins, sans-serif",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: true,
      },

      axisTicks: {
        show: false,
      },
    },
    yRatio: 0,
    yaxis: {
      labels: {
        formatter: function (value) {
          return value >= 1000 ? "€" + Number(Number(value).toFixed(2)) / 1000 + "k" : Number(Number(value).toFixed(2)) + " €";
        },

        style: {
          colors: "#787878",
          fontSize: "20px",
          fontFamily: "Arial, sans-serif",
          fontWeight: 400,
        },
      },
    },
    series: [
      {
        name: "series1",
        data: [
          [3132, new Date()],
          [4038, new Date()],
          [287, new Date()],
          [5321, new Date()],
          [420, new Date()],
          [1090, new Date()],
          [7000, new Date()],
          [1000, new Date()],
        ],
        dataLabels: {
          enabled: false,
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: "#1F2937",
            opacity: 0.5,
          },
          {
            offset: 100,
            color: "#2B83FF",
            opacity: 0.3,
          },
        ],
      },
    },
    stroke: {
      dotted: true,
      curve: "smooth",
      colors: ["#1F2937"],
      width: 1,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      show: false,
    },
  });
  const [series, setSeries] = useState({
    name: "",
    data: [],
    dataLabels: {
      enabled: false,
    },
  });
  // date filter
  const [dateFilter, setDateFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  // this funciton calculates the improvement percentage

  const calculateImprovement = async (startDate, endDate) => {
    let startDate1 = moment(startDate).format("YYYY-MM-DD");
    let endDate1 = moment(endDate).format("YYYY-MM-DD");
    let diff = moment(endDate1).diff(moment(startDate1), "days");

    // console.log(diff)
    let oldStart = moment(startDate1)
      .subtract(diff, "days")
      .format("YYYY-MM-DD");

    if (diff <= 1) {
      oldStart = moment(startDate1).subtract(1, "days").format("YYYY-MM-DD");
      const oldPayments = await Payment.getPaymentsByDate(oldStart);
      const newPayments = await Payment.getPaymentsByDate(startDate1);
      let oldTotal = 0;
      let newTotal = 0;
      // console.log(oldPayments.map(e => e.data().amount), oldStart)
      // console.log(newPayments.map(e => e.data().amount), startDate1)
      oldPayments.forEach((p) => {
        oldTotal += p.data().amount;
      });

      newPayments.forEach((p) => {
        newTotal += p.data().amount;
      });
      let percentage = ((newTotal - oldTotal) / oldTotal) * 100;
      if (isNaN(percentage)) {
        percentage = 0;
      }
      if (percentage === Infinity) {
        percentage = newTotal;
      }
      console.log(oldTotal, newTotal, percentage);
      setImprovements(percentage.toFixed(2));
      return;
    }

    const oldPayments = await Payment.getPaymentsByDateRange(
      oldStart,
      startDate1
    );
    const newPayments = await Payment.getPaymentsByDateRange(
      startDate1,
      endDate1
    );
    let oldTotal = 0;
    let newTotal = 0;
    oldPayments.forEach((p) => {
      oldTotal += p.data().amount;
    });
    newPayments.forEach((p) => {
      newTotal += p.data().amount;
    });
    let percentage = ((newTotal - oldTotal) / oldTotal) * 100;
    if (isNaN(percentage)) {
      percentage = 0;
    }
    if (percentage === Infinity) {
      percentage = newTotal;
    }

    setImprovements(percentage.toFixed(2));
  };

  // this function returns an array of the dates between startDate-endDate

  const getAllDatesInRange = (
    startDate,
    endDate,
    format = "YYYY-MM-DD",
    steps = "days"
  ) => {
    const datesArray = [];
    const currentDate = moment(moment(startDate).format("YYYY-MM-DD"));
    const lastDate = moment(moment(endDate).format("YYYY-MM-DD"));

    while (currentDate <= lastDate) {
      datesArray.push(currentDate.format(format));
      currentDate.add(1, steps);
    }

    return datesArray;
  };

  const getAllPaymentsOfAllTime = async () => {
    setLoading(true);
    const payments = await Payment.getAllP();

    let data = [];

    let weekDates = getAllDatesInRange(
      moment(new Date()).subtract(7, "days"),
      new Date()
    );

    weekDates.forEach((e) => {
      data.push({
        amount:
          payments.docs
            .filter((p) => {
              return (
                moment(p.data().createdAt.toDate()).format("YYYY-MM-DD") === e
              );
            })
            .reduce((a, b) => a + b.data().amount, 0) || 0,
        date: e,
      });
    });

    let res = 0;
    data.forEach((e) => (res += e.amount));
    // console.log(res)
    setTodayRevenue(res);
    setDateFilter({
      startDate: new Date(weekDates[0]),
      endDate: new Date(weekDates[weekDates.length - 1]),
    });
    calculateImprovement(
      new Date(weekDates[0]),
      new Date(weekDates[weekDates.length - 1])
    );
    setSeries({
      ...series,
      data: data.map((e) => ({
        x: new Date(e.date).getDate().toString(),
        y: e.amount,
      })),
    });

    // console.log("result ", data.map((e) => [e.amount, e.date]))
    return setLoading(false);
  };

  const handleSelectMounths = async (range) => {
    setDateFilter({
      startDate: range.range1.startDate,
      endDate: range.range1.endDate,
    });

    // console.log(range.range1.startDate, range.range1.endDate)
    const payments = await Payment.getPaymentsByDateRange(
      range.range1.startDate,
      range.range1.endDate
    );
    // console.log(payments);

    const mounths = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let data = [];
    let dates = getAllDatesInRange(
      range.range1.startDate,
      range.range1.endDate,
      "MM",
      "months"
    );
    console.log(dates);
    // console.log(dates)

    dates.forEach((e) => {
      let payment = payments.filter(
        (p) => moment(p.data().createdAt.toDate()).format("MM") === e
      );
      console.log(payment);
      let amount = 0;
      payment.forEach((p) => {
        amount += p.data().amount;
      });

      data.push({
        amount: amount,
        date: e,
      });
    });
    let res = 0;
    data.forEach((e) => (res += e.amount));
    // console.log(res)
    setTodayRevenue(res);
    console.log(data);
    setSeries({
      ...series,
      data: data.map((e) => ({ x: mounths[Number(e.date) - 1], y: e.amount })),
    });

    setVisibleCalender(false);
    return setLoading(false);
  };

  const handleSelectOneDay = async (range) => {
    setDateFilter({
      startDate: range.range1.startDate,
      endDate: range.range1.endDate,
    });

    const payments = await Payment.getPaymentsByDate(range.range1.startDate);
    // console.log(payments);
    const data = payments.map((e) => ({
      amount: e.data().amount,
      date: moment(e.data().createdAt.toDate()).format("DD"),
    }));

    let resultDay = {
      amount: 0,
      date: "",
    };
    data.forEach((e) => {
      resultDay.amount += e.amount;
      resultDay.date = e.date;
    });
    // console.log(resultDay)

    let res = 0;
    data.forEach((e) => (res += e.amount));
    // console.log(res)
    setTodayRevenue(res);

    setSeries({
      ...series,
      data: [{ x: resultDay.date, y: resultDay.amount }],
    });
    setOptions({
      ...options,
      dataLabels: {
        enabled: true,
      },
    });
    setVisibleCalender(false);
    return setLoading(false);
  };

  const handleSelect = async (ranges) => {
    setOptions({
      ...options,
      dataLabels: {
        enabled: false,
      },
    });
    setLoading(true);

    setDateFilter({
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate,
    });

    calculateImprovement(ranges.range1.startDate, ranges.range1.endDate);

    if (ranges.range1.startDate === ranges.range1.endDate) {
      return handleSelectOneDay(ranges);
    }

    if (
      moment(ranges.range1.endDate).diff(
        moment(ranges.range1.startDate),
        "days"
      ) >= 31
    ) {
      return handleSelectMounths(ranges);
    }

    const payments = await Payment.getPaymentsByDateRange(
      ranges.range1.startDate,
      ranges.range1.endDate
    );
    // console.log(payments);
    let data = [];

    // const data2 = []

    let rangeDates = getAllDatesInRange(
      ranges.range1.startDate,
      ranges.range1.endDate
    );
    rangeDates.forEach((e) => {
      data.push({
        amount:
          payments
            .filter((p) => {
              return (
                moment(p.data().createdAt.toDate()).format("YYYY-MM-DD") === e
              );
            })
            .reduce((a, b) => a + b.data().amount, 0) || 0,
        date: e,
      });
    });

    // console.log(rangeDates, "data", data)

    let res = 0;
    data.forEach((e) => (res += e.amount));
    // console.log(res)
    setTodayRevenue(res);
    // console.log(data.map((e) => ({ x: new Date(e.date).getDate().toString(), y: e.amount })));
    setSeries({
      ...series,
      data: data.map((e) => ({
        x: new Date(e.date).getDate().toString(),
        y: e.amount,
      })),
    });

    setVisibleCalender(false);
    setLoading(false);
  };

  useEffect(() => {
    // window.addEventListener('touchstart', (e) => {
    //     if (e.target.id !== 'dateRange' && visibleCalender) {
    //         setVisibleCalender(false)
    //     }
    //     else {
    //         setVisibleCalender(true)
    //     }
    // });
    getAllPaymentsOfAllTime();
    // setLoading(true);
    // setTimeout(() => {
    //     setLoading(false);
    // }, 2000);
    return () => {
      window.removeEventListener("click", () => { });
    };
  }, []);

  return (
    <div className="w-full mx-auto mb-5 flex flex-col gap-5 justify-between items-center border-2 pt-4 px-3 lg:px-[3vw] lg:pt-[3vw] shadow-black/20 bg-white  rounded-3xl shadow-2xl">
      <div className="w-full flex flex-row justify-between">
        <div className="w-fit flex flex-col gap-5">
          <h1 className="font-semibold text-left text-gray-700 text-3xl font-poppins">Ventes</h1>
        </div>
        <div>
          <CSVLink
            aria-disabled={loading}
            filename={
              "REPORT-(" +
              dateFilter.startDate.toDateString() +
              " - " +
              dateFilter.endDate.toDateString() +
              ").csv"
            }
            data={series.data.map((e) => {
              return {
                date: e.x,
                amount: e.y,
              };
            })}
            className="rounded-xl md:w-56 flex flex-row justify-around items-center gap-2 bg-blue-100 hover:bg-blue-200 px-4 py-3 transform mr-2 text-gray-800 font-semibold"
          >
            <img className="w-5" src={download} alt="export icon" />
            <span className="hidden md:block">Telécharger</span>
          </CSVLink>
        </div>
      </div>

      {loading ? (
        <div className="h-60 w-full flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="w-full flex flex-col h-full gap-10 pl-2 justify-around ">
          <div className="flex flex-row md:flex-nowrap flex-wrap gap-5 items-center">
            <h1 className="font-bold whitespace-nowrap text-[#163175] text-4xl ">
              {Number(Number(todayRevenue).toFixed(4))} €
            </h1>
            <span
              className={`${improvements > ZERO.toFixed(2)
                  ? "text-green-500"
                  : improvements === ZERO.toFixed(2)
                    ? "text-gray-500"
                    : "text-red-500"
                } flex flex-row  items-center gap-2`}
            >
              <svg
                className={`w-3 h-3 ${improvements > ZERO.toFixed(2)
                    ? "text-green-500"
                    : "text-red-500"
                  } transform ${improvements > ZERO.toFixed(2)
                    ? "rotate-90"
                    : improvements === ZERO.toFixed(2)
                      ? "hidden"
                      : "-rotate-90"
                  }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 5H1m0 0 4 4M1 5l4-4"
                />
              </svg>
              {improvements}%
            </span>
            <div className="w-full relative flex justify-end">
              <div
                className={`${visibleCalender ? "block" : "hidden"
                  } absolute top-[80%] border-2 border-gray-300 shadow-2xl shadow-gray-400 rounded-2xl overflow-hidden z-50`}
              >
                <DateRange
                  locale={fr}
                  ranges={[dateFilter]}
                  onChange={handleSelect}
                />
              </div>
              <Button
                id="dateRange"
                onClick={() => setVisibleCalender(!visibleCalender)}
                variant="outlined"
                className="rounded-xl border-2 hover:bg-gray-300 flex flex-row justify-around md:w-56 items-center gap-2 border-gray-300 p-3 md:px-4 md:py-3 mr-2 text-gray-800 font-semibold"
              >
                <img
                  id="dateRange"
                  className="w-6"
                  src={dateIcon}
                  alt="export icon"
                />
                <span
                  id="dateRange"
                  className="hidden md:block font-bold lg:text-xs"
                >
                  {moment(dateFilter.startDate).format("DD/MM/YYYY")} -{" "}
                  {moment(dateFilter.endDate).format("DD/MM/YYYY")}
                </span>
              </Button>
            </div>
          </div>
          <div className="">
            <Chart
              options={options}
              series={[series]}
              type="area"
              width="100%"
              height="400px"
            />
          </div>
        </div>
      )}
    </div>
  );
}

// logic inside the handle select day fucntion to return hours
// console.log(payments);

// let data = [];
// let days = [
//     "00H",
//     "01H",
//     "02H",
//     "03H",
//     "04H",
//     "05H",
//     "06H",
//     "07H",
//     "08H",
//     "09H",
//     "10H",
//     "11H",
//     "12H",
//     "13H",
//     "14H",
//     "15H",
//     "16H",
//     "17H",
//     "18H",
//     "19H",
//     "20H",
//     "21H",
//     "22H",
//     "23H",
// ];

// days.forEach((e) => {
//     data.push({
//         amount: payments.filter((p) => {
//             return (
//                 moment(p.data().createdAt.toDate()).format("HH") + "H" === e
//             );
//         }).reduce((a, b) => a + b.data().amount, 0) || 0,
//         date: e,
//     });
// });

// console.log(data.map((e) => [e.date, e.amount]));
