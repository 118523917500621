import React, { useState, useEffect } from "react";

import ParamTable from "../components/paramsTable";

const ParamsPage = () => {
  return (
    <div className="w-full flex-1 pt-10 overflow-hidden">
      <ParamTable />
    </div>
  );
};

export default ParamsPage;
