import React, { useContext, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import * as Yup from "yup";
import { useFormik } from "formik";
import logofull from "../assets/img/full-logo.svg";
import bg from "../assets/img/login-bg.svg";
import AlertContext from "../context/AlertContext/allertContext";

const ForgotPasswordPage = () => {
  const sendResetPasswordEmail = async (email) => {
    const auth = getAuth();
    return await sendPasswordResetEmail(auth, email);
  };
  const { showAlert } = useContext(AlertContext);
  const [message, setMessage] = useState(null);
  const validation = useFormik({
    //enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required("Veuillez entrer votre adresse e-mail."),
    }),
    onSubmit: async (values) => {
      await sendResetPasswordEmail(values.email)
        .then(() => {
          showAlert("L'email de réinitialisation a été envoyé avec succès");
          setMessage({
            message: "L'email de réinitialisation a été envoyé avec succès",
            type: "success",
          });
        })
        .catch((error) => {
          showAlert(getErrorMessage(error), "error");
          setMessage({
            message: getErrorMessage(error),
            type: "error",
          });
        });
    },
  });

  function getErrorMessage(error) {
    switch (error?.code) {
      case "auth/invalid-email":
        return "L'email saisi est invalide.";
      case "auth/user-not-found":
        return "L'utilisateur avec cet email n'existe pas.";
      default:
        return "Une erreur s'est produite lors de l'envoi de l'email de réinitialisation.";
    }
  }

  return (
    <div className="min-h-screen flex">
      <div className="flex flex-col lg:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white">
        <div
          className="lg:w-1/2 bg-cover w-full h-full md:flex hidden flex-auto items-center justify-center lg:p-10 md:p-5 p-3 overflow-hidden text-white"
          style={{ background: "url(" + bg + ")" }}
        >
          <div className="w-full max-w-md z-10">
            <div className="h-1/6 mb-10 flex flex-col justify-center">
              <img src={logofull} className="w-80 h-80" alt="" />
            </div>
            <h2 className="xl:text-3xl lg:text-2xl md:text-xl lg:w-5/6 w-full text-base text-left font-bold leading-tight mb-6">
              Découvrez une nouvelle façon de visiter.
            </h2>
            <p className="xl:text-xl text-left  lg:w-4/6 w-full  font-light lg:text-lg md:text-base text-sm text-gray-200">
              Les lieux incontournables de chaque ville.
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center h-full w-full lg:w-1/2 pt-8 md:pt-0 xl:p-8 lg:p-4 p-2 md:p-10  sm:rounded-lg md:rounded-none relative">
          <div className="md:w-2/3 w-full">
            <div className="text-center">
              <h2 className="mb-16 xl:text-3xl lg:text-xl text-lg font-bold text-orange-600">
                Bienvenue
              </h2>
              <p className="lg:text-xl md:text-base text-sm font-bold text-blue-950">
                Réinitialisation du mot de passe
              </p>
            </div>
            <div className="flex flex-row justify-center items-center space-x-3 mt-5">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
                className="w-full"
              >
                <div className="relative">
                  <label
                    htmlFor="email-address"
                    className="ml-3 text-sm font-medium text-gray-700 tracking-wide absolute px-2 bg-white -top-3 -left-1"
                  >
                    Adresse e-mail <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoFocus
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    className="w-full text-base px-4 py-3 border rounded-lg border-gray-300 focus:outline-none focus:border-indigo-500"
                    placeholder="Saisissez votre adresse e-mail"
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <p className="text-red-500 text-sm ml-2">
                      {validation.errors.email}
                    </p>
                  ) : null}
                </div>

                {!!message && (
                  <p
                    className={`text-sm ml-2 mt-3 ${
                      message.type === "error"
                        ? "text-red-500"
                        : "text-green-500"
                    }`}
                  >
                    {message?.message}
                  </p>
                )}
                <div className="mt-5 mb-12">
                  <button
                    type="submit"
                    className="w-full flex justify-center bg-gradient-to-r from-amber-400 to-amber-500  hover:bg-gradient-to-l hover:from-amber-400 hover:to-amber-500 p-4 rounded-xl tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500 text-gray-700"
                  >
                    Réinitialiser
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="absolute bottom-5 w-11/12 text-center">
            <p className="text-gray-400 text-sm font-semibold">
              © 2023 Girouette. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
