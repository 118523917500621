import React, { useEffect, useState } from "react";
import AlertContext from "./allertContext";

function AlertProvider({ children }) {
  const [show, setShow] = useState(false);
  const [msg, setMessage] = useState("");
  const [type, setType] = useState("");

  const showAlert = (msg, type) => {
    setMessage(msg);
    setType(type);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  useEffect(() => {
    setMessage("");
    setType("success");
  }, []);

  return (
    <AlertContext.Provider
      value={{ show, showAlert, msg, setMessage, type, setType }}
    >
      {children}
    </AlertContext.Provider>
  );
}

export default AlertProvider;
