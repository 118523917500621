import axios from "axios";

// export const baseURL = "http://localhost:3001";
export const baseURL = "https://girouette-back.kamandcogroup.com";
export const baseImageURL = "https://girouette-back.kamandcogroup.com/image/";
// export const googleMapsApiKey = "AIzaSyCo-WDvFtAc6tzSYqoOAakCzB2isXkQkn0";
export const googleMapsApiKey = "AIzaSyB6xJuJG9zvaK8yS9ExgJBkquDloinsKzc";

export const MUSEUM_CATEGORY_ID = "uAP7fQktKFIUDWJkfRHU";
const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export default instance;
