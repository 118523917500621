import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SideBareContext from "../context/SideBareContext/SideBareContext";
import { signOut } from "firebase/auth";
import { auth } from "../utils/firebase";

import logo from "../assets/logo.png";
import logoutIcon from "../assets/icons/logout.svg";

import categoryIcon from "../assets/icons/sidebar/category.svg";
import close from "../assets/icons/sidebar/close.png";
import diagramIcon from "../assets/icons/sidebar/diagram.svg";
import locationIcon from "../assets/icons/sidebar/location.svg";
import notificationIcon from "../assets/icons/sidebar/notification.svg";
import paperIcon from "../assets/icons/sidebar/paper.svg";
import userIcon from "../assets/icons/sidebar/user.svg";
import accountingIcon from "../assets/icons/sidebar/accounting.svg";
import visitIcon from "../assets/icons/sidebar/visit.svg";

const SideBarTest = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const location = useLocation();

  const items = [
    {
      name: "Dashboard",
      route: "/",
      icon: diagramIcon,
    },
    {
      name: "Villes",
      route: "/cities",
      icon: locationIcon,
    },

    {
      name: "Thèmes",
      route: "/themes",
      icon: categoryIcon,
    },
    {
      name: "Visites",
      route: "/visits",
      icon: visitIcon,
    },
    {
      name: "Points",
      route: "/points",
      icon: paperIcon,
    },

    {
      name: "Comptabilité",
      route: "/accounting",
      icon: accountingIcon,
    },
    {
      name: "Notifications",
      route: "/notifications",
      icon: notificationIcon,
    },
    {
      name: "Administration",
      route: "/admins",
      icon: userIcon,
    },
    // {
    //   name: "Abonnement",
    //   route: "/abonnement",
    //   icon: ticketIcon,
    // },
  ];
  const { activeRoute, setActiveRoute } = useContext(SideBareContext);
  const toggle = () => {
    setShowMobileNav(!showMobileNav);
  };

  useEffect(() => {
    const path = location.pathname;
    setActiveRoute(path);
    console.log("path");
    console.log(path);
    console.log("path");
  }, [location, setActiveRoute]);

  return (
    <div
      className={`fixed w-full overflow-x-hidden bg-gray-700 flex flex-row justify-between lg:p-0 px-3 py-4 items-center z-10 `}
    >
      <button className="text-white lg:hidden flex" onClick={toggle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-7 h-7"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
      <div
        className={`lg:hidden items-center ${
          showMobileNav ? "hidden" : "flex"
        }`}
      >
        <img src={logo} className="h-7 w-auto " alt="" />
      </div>
      <button
        onClick={async () => {
          await signOut(auth);
        }}
        color="success"
        className="lg:hidden flex rounded-xl items-center lg:py-2 py-1 lg:px-4 px-2 text-amber-400 bg-gray-700"
      >
        <img src={logoutIcon} className="w-7 h-7" alt="" />
      </button>
      {/* SideMenu */}
      <div
        className={`lg:flex lg:fixed py-8 bg-gray-800 left-0 overflow-y-auto top-0 w-1/2 sm:w-1/3 lg:w-[25%] xl:w-[300px] h-screen text-white flex flex-col gap-8
         ${showMobileNav ? "fixed  z-20" : "hidden"}`}
      >
        <button className=" absolute top-2 right-2 lg:hidden" onClick={toggle}>
          <img src={close} alt="" />
        </button>
        <div className="flex flex-row justify-center items-center">
          <img src={logo} className="h-8 w-auto" alt="" />
        </div>
        <ul className="flex flex-col ml-8">
          {items.map((v) =>
            v.name === "Notifications" || v.name === "Administration" ? null : (
              <li
                key={v.name}
                className={`block cursor-pointer rounded-lg lg:w-[200px]   hover:bg-amber-500 hover:text-white px-3 py-2 m-1  ${
                  (v.route !== "/"
                    ? activeRoute.includes(v.route)
                    : activeRoute === v.route)
                    ? "text-white bg-amber-500"
                    : "text-gray-200"
                }  hover:text-amber-500`}
              >
                <NavLink
                  key={v.name}
                  onClick={() => {
                    setShowMobileNav(false);
                    setActiveRoute(v.route);
                  }}
                  to={v.route}
                  className="flex flex-row items-center"
                  href="#"
                >
                  {" "}
                  <img src={v.icon} className="mr-3 h-6 w-6" alt="" />
                  <div className="text-center text-base 2xl:text-lg">
                    {v.name}
                  </div>
                </NavLink>
              </li>
            )
          )}
        </ul>
        <div className="w-fit self-center">
          <button
            onClick={async () => {
              await signOut(auth);
            }}
            color="success"
            className="flex rounded-xl gap-2 items-center px-4 py-2 w-full text-amber-400 bg-gray-700"
          >
            <img
              src={logoutIcon}
              className=" font-medium w-6 h-6 text-lg"
              alt=""
            />
            Déconnexion
          </button>
        </div>
      </div>
      {/* Hide side bar when click is outside */}
      <div
        className={`fixed  w-screen min-h-screen top-0 right-0 z-10 ${
          showMobileNav ? " opacity-0" : "hidden"
        }`}
        onClick={() => setShowMobileNav(false)}
      ></div>
    </div>
  );
};

export default SideBarTest;
