import React, { useState, useEffect, useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../utils/firebase";
import SideBare from "../components/sideBare";
import { HashRouter, Outlet, Route, Router } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Alert from "../components/alert";
import AlertContext from "../context/AlertContext/allertContext";
import SideBareTest from "../components/sideBarTest";
import User from "../models/User";
import Admin from "../models/Admin";

const HomePage = () => {
  const { show, showAlert } = useContext(AlertContext);
  const [isSuperAdmin, SetSuperAdmin] = useState(false);

  /*
  const callBack = (data) => {
    console.log(data.map((admin) => new Admin(admin)));
  };

  useEffect(() => {
    const admins = Admin.getAllAdmins(callBack);
    console.log("unsubscribe", admins);
    return () => admins();
  }, []);
*/
  /*
  const callBack2 = (data) => {
    console.log(data.map((user) => new User(user)));
  };

  useEffect(() => {
    const unsubscribe = User.getAllUsers(callBack2);
    console.log("unsubscribe user", unsubscribe);
    return () => unsubscribe();
  }, []); */
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        console.log("uid", uid);
      } else {
        // User is signed out
        // ...
        console.log("user is logged out");
      }
    });
  }, []);

  return (
    <div className="w-full  flex lg:flex-row flex-col overflow-y-auto ">
      <SideBareTest />
      {show && <Alert />}
      <div className="lg:pl-[25%] xl:pl-[300px] py-[68px]  lg:py-0    w-full flex flex-col ">
        <Header />
        <div className=" lg:py-10 mt-10">
          <Outlet />
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default HomePage;
