import React, { useContext } from "react";
import AlertContext from "../context/AlertContext/allertContext";

const Alert = () => {
  const { msg, type = "success" } = useContext(AlertContext);
  return (
    <div
      className={`p-4 absolute z-50 top-24 right-10 rounded-sm ${
        type === "success" ? "bg-green-400" : "bg-red-400"
      }`}
    >
      <p className="text-gray-100">{msg}</p>
    </div>
  );
};
export default Alert;
