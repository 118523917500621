import React from "react";

const Loader = () => {
  return (
    <div className="w-screen h-screen bg-[#1F2937] flex items-center justify-center">
      <div className="loader"></div>;
    </div>
  );
  // <div className='w-full h-full bg-amber-500 flex flex-col justify-center items-center'>
  //     <img className="w-64" src={logo} />
  // </div>;
};
export default Loader;
