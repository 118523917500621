import React, { useState, useEffect } from "react";
import SubHeader from "../components/GeneralePageComponents/SubHeader";
import SalesSummery from "../components/GeneralePageComponents/SalesSummery";
import SalesChart from "../components/GeneralePageComponents/SalesChart";
import VisitsStats from "../components/GeneralePageComponents/VisitsStats";
import ThemeStats from "../components/GeneralePageComponents/ThemeStats";

const GeneralePage = () => {
  return (
    <div className=" py-10 md:w-full lg:w-[95%] mx-auto flex flex-col  gap-4 h-full px-2">
      <SalesSummery />
      <SalesChart />
      <VisitsStats/>
      <ThemeStats />
    </div>
  );
};

export default GeneralePage;
