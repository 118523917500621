import React, { useState, useContext } from "react";
import AdminFormular from "./adminForm";
import DataTable from "react-data-table-component";
import { deleteFile } from "../api/fileService";
import AlertContext from "../context/AlertContext/allertContext";
import SuppDialog from "./suppDialog";
import LoadingSpinner from "./loadingSpinner";
// import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

import plusIcon from "../assets/icons/plus.svg";
import eyeIcon from "../assets/icons/show.svg";
import lockIcon from "../assets/icons/lock.svg";
import deleteIcon from "../assets/icons/delete.svg";
import Admin, { ROLE_MANAGER, ROLE_SUPER_ADMIN } from "../models/Admin";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseImageURL } from "../api/axiosConfig";
import { auth } from "../utils/firebase";
import { deleteUser } from "firebase/auth";

const AdminTable = () => {
  const [showFormular, setShowFormular] = useState(false);
  const navigate = useNavigate();

  const [selectedAdmin, setSelectedAdmin] = useState(undefined);
  const [displayedData, setDisplayedData] = useState([]);
  const [data, setData] = useState([]);

  const { showAlert } = useContext(AlertContext);

  const [loding, setLoding] = useState(false);
  const [currentUserRole, seCurrentUserRole] = useState(ROLE_MANAGER);

  const [open, setIsOpen] = useState(false);
  const callBack = (data) => {
    setDisplayedData(data.map((admin) => new Admin(admin)));
    setData(data.map((admin) => new Admin(admin)));
    setLoding(false);

    data.forEach((admin) => {
      console.log("admin", admin);
      console.log("auth.currentUser.uid", auth.currentUser.email);
      if (admin.email === auth.currentUser.email) {
        seCurrentUserRole(admin.role);
      }
    });
  };

  const onChange = async (e) => {
    let adminsData = data;
    if (e.target.value === "") {
      setDisplayedData(adminsData);
      return;
    }
    adminsData = adminsData.filter((admin) => {
      return (
        admin?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        admin?.prenom?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        admin?.email?.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    console.log(adminsData);
    setDisplayedData(adminsData);
  };

  useEffect(() => {
    const unsubscribe = Admin.getAllAdmins(callBack);
    console.log(auth.currentUser.uid);
    return () => unsubscribe();
  }, []);

  const columns = [
    {
      name: <span className="text-lg font-bold">Nom</span>,
      selector: (row) => row?.name,
      minWidth: "200px",
      cell: (row) => {
        return (
          <div className="flex items-center gap-2 py-2">
            <img
              alt={row.id}
              src={row.avatar}
              className="w-12 h-12 rounded-full object-cover"
            />
            <span className="font-semibold capitalize text-sm">
              {row?.name}
            </span>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="text-lg font-bold">Role</span>,
      width: "150px",

      selector: (row) => (
        <div className="font-semibold capitalize text-sm">
          {row.role ?? "Manager"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="text-lg font-bold">Adresse Email</span>,
      // width: "200px",

      selector: (row) => (
        <div className="font-semibold lowercase text-sm">
          {row.email ?? "-"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="text-lg font-bold">Statut</span>,
      width: "140px",
      selector: (row) => {
        if (row.is_active) {
          return (
            <div className="w-20 px-2 py-1 font-semibold text-center bg-green-500 rounded-full text-white">
              Active
            </div>
          );
        } else {
          return (
            <div className="w-20 px-2 py-1 font-semibold text-center bg-red-500 rounded-full text-white">
              Inactive
            </div>
          );
        }
      },

      sortable: true,
    },
    {
      name: <span className="text-lg font-bold">Actions</span>,
      sortable: false,
      width: "100px",

      cell: (row) => {
        return (
          <div className="flex flex-row items-center gap-3">
            <img
              src={eyeIcon}
              alt="show"
              className="cursor-pointer"
              onClick={() => {
                navigate(`/admins/${row.id}`);
              }}
            />

            <img
              src={lockIcon}
              alt="Réinitialiser mot de passe"
              className=" cursor-pointer  h-5"
              onClick={async () => {
                await row
                  .sendResetPasswordEmail()
                  .then(() => {
                    showAlert(
                      "E-mail de réinitialisation du mot de passe envoyé."
                    );
                  })
                  .catch(() => {
                    showAlert(
                      "Erreur lors de l'envoi de l'e-mail de réinitialisation du mot de passe.",
                      "error"
                    );
                  });
              }}
            />

            {row.role !== ROLE_SUPER_ADMIN &&
              currentUserRole === ROLE_SUPER_ADMIN && (
                <img
                  src={deleteIcon}
                  alt="delete"
                  className="cursor-pointer"
                  onClick={() => {
                    setIsOpen(true);
                    setSelectedAdmin(row);
                  }}
                />
              )}
          </div>
        );
      },
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "white",
        backgroundColor: "#1F2937",
      },
    },
  };
  return (
    <div className="h-full pr-1  px-5 " key="table">
      {open && (
        <SuppDialog
          open={open}
          setIsOpen={setIsOpen}
          setRow={setSelectedAdmin}
          text={"Êtes-vous sûr de vouloir supprimer ce utilisateur ?"}
          onConfirme={async () => {
            try {
              await deleteUser(selectedAdmin);
              await deleteFile(selectedAdmin.image);
              selectedAdmin.delete();

              setIsOpen(false);
              setSelectedAdmin(undefined);
              showAlert("Le point a été supprimée avec succès.");
            } catch (e) {
              if (e.request.status === 500) {
                selectedAdmin.delete();
                setIsOpen(false);
                setSelectedAdmin(undefined);
                showAlert("Le point a été supprimée avec succès.");
              }
            }
          }}
        />
      )}

      {showFormular && (
        <AdminFormular
          setShowFormular={setShowFormular}
          data={selectedAdmin}
          setSelectedAdmin={setSelectedAdmin}
          setdata={setSelectedAdmin}
        />
      )}
      <div className="h-full w-full p-7 ">
        {loding ? (
          <div className="h-40 w-full flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="bg-white max-w-full lg:p-7 pl-1 rounded-3xl  ">
            <div className="flex flex-col">
              <div className="mb-4 flex lg:flex-row flex-col justify-between items-center">
                <h1 className="lg:text-3xl md:text-xl text-lg font-bold leading-tight text-gray-900">
                  Administration
                </h1>

                <div className="flex items-center py-2 lg:w-1/3 w-full">
                  <input
                    onChange={onChange}
                    className="bg-white appearance-none border-2 border-amber-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                    id="inline-searcg"
                    type="text"
                    placeholder="Recherche"
                  />
                </div>
                <div className="flex items-center py-2">
                  <button
                    onClick={() => setShowFormular(true)}
                    className="flex items-center rounded-full text-base pl-4 pr-1 py-1 border border-transparent leading-5 font-medium  text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:shadow-outline"
                  >
                    Créer un admin
                    <img
                      src={plusIcon}
                      alt="add"
                      className="w-10 h-10 pl-3  "
                    />
                  </button>
                </div>
              </div>
              {/* {
                displayedData.length === 0 ? (
                  <div className="flex items-center justify-center h-40">
                    <LoadingSpinner />
                  </div>
                )
                  : */}
              <DataTable
                columns={columns}
                noDataComponent="Aucun résultat trouvé"
                data={displayedData}
                customStyles={customStyles}
                pagination
              />

              {/* } */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AdminTable;
