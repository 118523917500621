import React, { useState, useEffect, useRef } from "react";

import { registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import Point from "../models/Point";

import Visit from "../models/Visit";
import MapComponent from "./Map";
import { where } from "firebase/firestore";

import { Spinner } from "@material-tailwind/react";
import { baseImageURL, googleMapsApiKey } from "../api/axiosConfig";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { toogleOverflow } from "../utils/helpers/style_helpers";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const ShowPointForm = ({ setShowPoint, data, setSelectedPoint, setData }) => {
  //const [image, setImage] = useState('');

  const [selectedVisit, setSelectedVisit] = useState(undefined);

  const [showMap, setShowMap] = useState(false);

  const [lat, setLat] = useState(46.76374291690988);
  const [lng, setLng] = useState(2.263165173084807);

  const imageFilePondRef = useRef(null);

  const [showLoader, setShowLoader] = useState(false);

  const containerStyle = {
    width: "100%",
    height: "200px",
  };

  const onChange = async (val) => {
    const data = await Point.getAllPt([where("idVisit", "==", val.value)]);
    let dataF = [];
    data.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const id = doc.id;
      dataF.push(new Point({ id, ...doc.data() }));
    });
    dataF.sort(function (a, b) {
      return a.order - b.order;
    });

    setSelectedVisit(val);
  };

  const formatLink = (link) => {
    const index = link.indexOf("&ab_channel=");
    let updatedUrl = index !== -1 ? link.slice(0, index) : link;

    updatedUrl = updatedUrl.replace(
      "https://www.youtube.com/watch?v=",
      "https://www.youtube.com/embed/"
    );

    updatedUrl = updatedUrl.replace("view", "preview");

    return updatedUrl;
  };

  useEffect(() => {
    const loadVisits = async () => {
      const querySnapshot = await Visit.getAllVs();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData.push({ value: doc.id, label: doc.data().name });
      });
      if (data !== undefined) {
        let d = myData.filter((e) => e.value === data.idVisit);
        //setSelectedVisit(d);
        if (d.length > 0) onChange(d[0]);
        setLat(data.coordinates[0]);
        setLng(data.coordinates[1]);
      }
    };
    loadVisits();

    const filePond = imageFilePondRef.current;
    const fetchFile = async (name, isVideo) => {
      if (!name) return;
    };

    if (data) {
      setShowLoader(true);
      filePond?.removeFiles();
      fetchFile(data.image, false);
      if (data.video?.includes("http")) {
        setShowLoader(false);
      } else fetchFile(data.video, true).then(() => setShowLoader(false));
    }

    toogleOverflow();
    return () => {
      toogleOverflow();
    };
  }, []);

  const handleMapClick = (data) => {
    setLat(data.latLng.lat());
    setLng(data.latLng.lng());
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        return false;
      }}
      action="#"
    >
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        {showMap && (
          <MapComponent
            lat={parseFloat(lat)}
            lng={parseFloat(lng)}
            setLat={setLat}
            setLng={setLng}
            setShowMap={setShowMap}
            onClick={handleMapClick}
          />
        )}
        <div className="flex relative items-center overflow-hidden justify-center  h-full  text-center sm:block  ">
          {showLoader && (
            <div className="absolute w-full  h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner className="h-10 w-10 text-main self-center" />
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-3xl text-left  
            shadow-xl transform transition-all overflow-y-auto h-[calc(100%-4rem)]     sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  Consulter une point
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setShowPoint(false);
                    setData(undefined);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              <div className="mb-3 flex lg:flex-row flex-col gap-3">
                <div className="lg:w-1/2 w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Nom
                  </label>
                  <p>{data.name}</p>
                </div>

                <div className="lg:w-1/2 w-full">
                  <label className="text-gray-800 block mb-2 font-bold">
                    Visits
                  </label>
                  <p>{selectedVisit?.label}</p>
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="description"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Description
                </label>

                <p>{data.description}</p>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="address"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  adresse
                </label>
                <p>{data.address}</p>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="longtitude"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Localisation
                </label>
              </div>
              <LoadScript
                googleMapsApiKey={googleMapsApiKey}
                libraries={["places"]}
                className="h-52"
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{
                    lat: data.coordinates?.[0],
                    lng: data.coordinates?.[1],
                  }}
                  zoom={14}
                >
                  <Marker
                    key={`${data.coordinates?.[0] * data.coordinates?.[1]}`}
                    position={{
                      lat: data.coordinates?.[0],
                      lng: data.coordinates?.[1],
                    }}
                  />
                </GoogleMap>
              </LoadScript>

              <div className="w-full ">
                <label className="text-gray-800 block mb-2 font-bold">
                  Image
                </label>
                <img
                  className=" h-52  w-full object-cover rounded-lg"
                  src={baseImageURL + data.image}
                  alt={data.name}
                />
              </div>

              <div className="w-full">
                <label className="text-gray-800 block mb-2 font-bold">
                  Video
                </label>

                {data.video?.includes("http") ? (
                  // <iframe
                  //   src={data.video}
                  //   height="200"
                  //   width="100%"
                  //   title="Iframe Example"
                  // ></iframe>

                  <iframe
                    className="w-full "
                    height="200"
                    src={formatLink(data.video)}
                    title="test"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <video className="w-full " height="200" controls>
                    <source src={baseImageURL + data.video} />
                  </video>
                )}
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                onClick={() => {
                  setShowPoint(false);
                  setData(undefined);
                }}
                type="button"
                className="md:py-3 py-2 md:px-8 px-4 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ShowPointForm;
