import axios from "./axiosConfig";

export const uploadFile = async (file, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        const response = await axios.post(
          "/upload",
          { image: reader.result },
          {
            onUploadProgress: onUploadProgress,
          }
        );
        resolve(response.data.newFileName);
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsDataURL(file.file);
  });
};

export const downloadFromDrive = async (data) => {
  const response = await axios.post("/download_from_drive", data);
  return response;
};

export const getFile = async (imageName, onDownloadProgress) => {
  try {
    const response = await axios.get(`image/${imageName}`, {
      responseType: "blob",
      onDownloadProgress: onDownloadProgress,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteFile = async (fileName) => {
  try {
    await axios.delete(`/delete/${fileName}`);
    //console.log('File deleted successfully!');
  } catch (error) {
    // console.error('Error deleting file:', error);
    throw error;
  }
};
