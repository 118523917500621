import CategoryTable from "../components/categoryTable";

const CategoryPage = () => {
  return (
    <div className="w-full px-4">
      <CategoryTable />
    </div>
  );
};

export default CategoryPage;
