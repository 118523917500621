import React, { useEffect, useState } from 'react';
import { auth } from '../../utils/firebase';
import { Auth } from 'firebase/auth';
import AuthContext from './AuthContext';

function AuthProvider({ children }) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            console.log(currentUser);
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

export default AuthProvider;