import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Point from "../models/Point";
import { where } from "firebase/firestore";
import { calculateDistance } from "../utils/helpers/geo_helpers";
import { toogleOverflow } from "../utils/helpers/style_helpers";

const DargAndDropList = ({ visit, setShowPoints, setSelectedVisit }) => {
  const [points, setPoints] = useState([]);

  useEffect(() => {
    toogleOverflow();
    const fetchData = async () => {
      const data = await Point.getAllPt([where("idVisit", "==", visit.id)]);

      let dataF = [];
      data.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const id = doc.id;
        const pointDoc = doc.data();
        dataF.push(new Point({ id, ...pointDoc }));
      });
      dataF.sort(function (a, b) {
        return a.order - b.order;
      });
      setPoints(dataF);
    };
    fetchData();

    return () => {
      toogleOverflow();
    };
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Dragged outside the list
    const newItems = Array.from(points);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setPoints(newItems);
  };
  return (
    <div className="fixed overflow-scrol z-50 top-0 w-screen h-screen left-0">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75" />
      </div>

      <div className="w-screen h-screen flex items-center relative">
        <div className="h-1/2 w-full px-5 md:w-2/3 xl:w-1/2 m-auto">
          <div className="bg-white flex-1 h-full rounded-xl overflow-y-auto">
            <h2 className="font-bold p-3 lg:text-xl md:text-lg text-base text-orange-500">
              Liste des points
            </h2>
            <DragDropContext onDragEnd={handleDragEnd}>
              {points.length > 0 ? (
                <Droppable droppableId="item-list">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="flex flex-col rounded-md capitalize "
                    >
                      {points.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className=" p-2 m-2 text-white rounded-md bg-[#1F2937]   "
                            >
                              {index + 1} - {item.name}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <h1>Aucun point n'a été trouvé.</h1>
                </div>
              )}
            </DragDropContext>
          </div>
          <div className="flex gap-3 ">
            <div
              onClick={async () => {
                var distance = 0;
                for (let index = 0; index < points.length - 1; index++) {
                  const firstPoint = points[index];
                  const secoundPoint = points[index + 1];
                  distance += calculateDistance(
                    firstPoint?.coordinates?.[0],
                    firstPoint?.coordinates?.[1],
                    secoundPoint?.coordinates?.[0],
                    secoundPoint?.coordinates?.[1]
                  );
                }
                visit.distance = parseFloat(distance.toFixed(2));
                await visit.save();
                points.forEach(async (value, index) => {
                  value.order = index;
                  try {
                    await value.save();
                  } catch (e) {
                    console.log(e);
                  }
                });

                setSelectedVisit(undefined);
                setShowPoints(false);
              }}
              className=" mt-4 bg-amber-500 p-4 rounded-lg hover:bg-amber-600 text-white font-bold text-xl text-center cursor-pointer"
            >
              Enregistrer
            </div>

            {/* <div className=" px-4 text-center"> */}
            <button
              onClick={() => {
                setSelectedVisit(undefined);
                setShowPoints(false);
              }}
              type="button"
              className="md:py-3  mt-4 py-2 md:px-8 px-4 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
            >
              Fermer
            </button>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DargAndDropList;
