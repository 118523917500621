import { useContext, useEffect } from "react";
import User from "../models/User";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import AlertContext from "../context/AlertContext/allertContext";
import UserBlockModal from "../components/UserBlockModal";
import LoadingSpinner from "../components/loadingSpinner";
import editIcon from "../assets/icons/edit.svg";
import enableIcon from "../assets/icons/enable.png";
import UserEditForm from "../components/userEditForm";
import Visit from "../models/Visit";
import Payment from "../models/payment";
import { where } from "firebase/firestore";

export default function ShowUser() {
  const id = useParams().id;
  const [loading, setLoading] = useState(true);
  const [loadingPayments, setLoadingPayments] = useState(true);
  const [user, setUser] = useState({});
  const [payments, setPayments] = useState([]);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const [showFormular, setShowFormular] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);

  const getUser = async () => {
    try {
      const user = await User.getUserById(id);
      const userInstance = new User({
        id: user.id,
        ...user.data(),
      });
      const paymentsSnapshot = await Payment.getAllP([
        where("userId", "==", user.id),
      ]);
      const paymentDataPromises = paymentsSnapshot.docs.map(
        async (paymentDoc) => {
          const payment = paymentDoc.data();
          try {
            const visit = await Visit.getOne(payment?.visitsId);
            const visitData = visit.data();
            return { ...payment, name: visitData?.name };
          } catch (error) {
            return { ...payment, name: "Visit Suprimmer" };
          }
        }
      );
      const paymentData = await Promise.all(paymentDataPromises);

      setUser(userInstance);
      setPayments(paymentData);
      setLoading(false);
    } catch (error) {
      // Handle error
    } finally {
      setLoadingPayments(false);
    }
  };

  const columns = [
    {
      name: "Date d'achat",
      selector: "date",
      sortable: true,
      cell: (row) => moment(row?.createdAt.toDate()).format("DD/MM/YYYY"),
    },
    {
      name: "Nom de la visite",
      selector: "date",
      sortable: true,
      cell: (row) => (
        <span className={row?.name ? "" : "text-gray-500"}>
          {row?.name ?? "Visite supprimée"}
        </span>
      ),
    },
    {
      name: "Prix (€)",
      sortable: true,
      selector: (row) => row?.amount,
      cell: (row) => <p className="flex ml-auto w-full ">{row?.amount} €</p>,
    },
  ];

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target.id !== "actions") {
        setActionsOpen(false);
      }
    });
    return window.removeEventListener("click", (e) => {});
  }, []);

  useEffect(() => {
    getUser();
  }, [id]);

  return (
    <div
      className={
        "w-full flex justify-center h-full pt-5 " +
        (loading ? "items-center" : "")
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className=" w-full lg:ml-5  mx-auto mt-4  gap-5 pb-8">
          {showFormular && (
            <UserEditForm setShowFormular={setShowFormular} user={user} />
          )}
          {openBlockModal && (
            <UserBlockModal
              visible={openBlockModal}
              onClose={() => setOpenBlockModal(false)}
              selectedUser={user}
              onConfirm={async () => {
                setOpenBlockModal(false);

                if (!user.disabled_at) {
                  showAlert("Le user a été bloqué avec succès.");
                  user.disabled_at = new Date();
                } else {
                  showAlert("Le user a été activé avec succès.");
                  user.disabled_at = null;
                }
                await user.save();
                await getUser();
              }}
            />
          )}
          <div className="flex-1 w-[95%]  mx-auto bg-white rounded-xl py-5">
            <div className="w-full flex  flex-row justify-between items-end relative">
              <div className=" lg:px-12 md:px-5 px-2">
                <h1 className="lg:text-xl text-lg font-semibold text-[#1f2937] mb-1 flex flex-row justify-between flex-wrap-reverse">
                  <p>Informations personnelles</p>
                  <Link
                    to={"/accounting"}
                    className="absolute  flex flex-row gap-2 items-start top-0 right-2 lg:right-12 md:right-5 cursor-pointer text-gray-300 hover:text-[#1f2937] font-poppins text-sm font-medium"
                  >
                    <svg
                      class="w-4 h-4 -rotate-90 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 18 18"
                    >
                      <path d="M17 0h-5.768a1 1 0 1 0 0 2h3.354L8.4 8.182A1.003 1.003 0 1 0 9.818 9.6L16 3.414v3.354a1 1 0 0 0 2 0V1a1 1 0 0 0-1-1Z" />
                      <path d="m14.258 7.985-3.025 3.025A3 3 0 1 1 6.99 6.768l3.026-3.026A3.01 3.01 0 0 1 8.411 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V9.589a3.011 3.011 0 0 1-1.742-1.604Z" />
                    </svg>
                    <p className="my-auto">Retour</p>
                  </Link>
                </h1>
                <div className="flex flex-row justify-start gap-4 items-center">
                  <img
                    src={user.profile}
                    className="w-28 h-28 rounded-full mb-3 border-2 border-yellow-500 object-cover"
                  />
                  <h2 className="text-xl font-bold text-[#1f2937] mb-1">
                    {user.name}
                    <p className="text-gray-600 text-base font-normal">
                      {user.email}
                    </p>
                  </h2>
                </div>
                <p className="text-gray-500 lg:text-base text-sm">
                  Informations de base, sur l'utilisateur et son compte
                </p>
              </div>
              <div className=" relative h-[100%] flex flex-col  justify-end px-5 items-end">
                <button
                  id="actions"
                  onClick={() => setActionsOpen(!actionsOpen)}
                  className="px-5 py-3 h-fit rounded-xl bg-transparent text-yellow-600 hover:text-yellow-900 hover:bg-gray-200"
                >
                  Actions
                </button>
                <div
                  id="actions"
                  className={`absolute ${
                    actionsOpen ? "flex " : "hidden"
                  } -top-[200%] -left-10 bg-white flex-col border-gray-200 border-2 shadow-2xl shadow-gray-400 rounded-xl gap-2 justify-center items-center p-4`}
                >
                  {/* <button
                    onClick={() => {
                      setShowFormular(true);
                    }}
                    className="cursor-pointer text-green-600 hover:bg-green-100 w-full flex rounded-xl justify-center items-center p-3"
                  >
                    <img
                      id="actions"
                      src={editIcon}
                      alt="edit"
                      className="w-4 h-4 mr-3"
                    />
                    Modifier
                  </button> */}
                  <button
                    id="actions"
                    onClick={() => {
                      setOpenBlockModal(true);
                      console.log(openBlockModal, "this is test visibility");
                    }}
                    className={`cursor-pointer ${
                      user.disabled_at
                        ? "text-green-600 hover:bg-green-100 bg-green-50"
                        : "text-red-500 hover:text-red-800 hover:bg-red-100 bg-red-50"
                    } w-full rounded-xl flex justify-center items-center p-3`}
                  >
                    {user.disabled_at ? (
                      <img
                        id="actions"
                        src={enableIcon}
                        alt="edit"
                        className="w-4 h-4 mr-3"
                      />
                    ) : (
                      <svg
                        class="w-4 h-4 mr-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    )}
                    {user.disabled_at ? "débloquer" : "bloquer"}
                  </button>
                </div>
              </div>
            </div>
            <div className="lg:px-12 md:px-5 px-2 py-2 bg-[#1f2937]/10 my-4">
              <h2 className="lg:text-base text-sm font-semibold text-[#1f2937]">
                Informations de base
              </h2>
            </div>
            <ul className="lg:px-12 md:px-5 px-2 mb-8">
              <li className="flex justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
                <span>Nom et prénom</span>
                <span>{user.name}</span>
              </li>
              <li className="flex justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
                <span>Adresse e-mail</span>
                <span>{user.email}</span>
              </li>

              <li className="flex justify-between text-gray-500 py-5 ">
                <spann>Date d'inscription</spann>
                <spann>
                  {moment(user.created_at.toDate()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                </spann>
              </li>
            </ul>

            <div className="w-full h-full flex lg:px-12 py-3 px-5 justify-end items-center"></div>
          </div>

          <div className="flex-1 w-[95%] mt-4  mx-auto bg-white rounded-xl py-5">
            <div className="w-full lg:px-12 px-5">
              <h1 className="lg:text-xl text-lg font-semibold text-[#1f2937] mb-1">
                Les achats
              </h1>

              <DataTable
                columns={columns}
                data={payments}
                customStyles={{
                  headCells: {
                    style: {
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      color: "",
                      backgroundColor: "#fefae7",
                    },
                  },
                }}
                pagination
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page:",
                  rangeSeparatorText: "sur",
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "Tous",
                }}
                noDataComponent="Aucune achat disponible"
                className="w-full"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
