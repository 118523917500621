import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import { FiPlusSquare } from "react-icons/fi";
import Notification from "../models/Notification";

import LoadingSpinner from "./loadingSpinner";
import NotificationForm from "./notificationForm";
import deleteIcon from "../assets/icons/delete.svg";
import SuppDialog from "./suppDialog";
import AlertContext from "../context/AlertContext/allertContext";
// import { formatDate } from "../utils/date";
import moment from "moment";

const NotificationTable = () => {
  const [data, setData] = useState([]);
  const [loding, setLoding] = useState(true);
  const [showFormul, setShowFormul] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(undefined);
  const { showAlert } = useContext(AlertContext);

  const callBack = (data) => {
    let dataF = data.map(
      (NotificationData) =>
        new Notification(
          NotificationData.id,
          NotificationData.title,
          NotificationData.message,
          NotificationData.creationDate
        )
    );
    setData(dataF);
    setLoding(false);
  };

  useEffect(() => {
    const unsubscribe = Notification.getAllNotifications(callBack);
    return () => unsubscribe();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "white",
        backgroundColor: "#1F2937",
      },
    },
  };
  const columns = [
    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold">
          Campagne
        </span>
      ),
      // width: "200px",
      selector: (row) =>
        row?.title?.charAt(0).toUpperCase() + row?.title?.slice(1),
      sortable: true,
    },

    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold">
          Message
        </span>
      ),
      width: "200px",

      selector: (row) => row?.message,
      cell: (row) => (
        <p className=" truncate" title={row?.message}>
          {row?.message}
        </p>
      ),
      sortable: true,
    },

    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold">Date</span>
      ),
      width: "200px",

      // selector: (row) => `${row?.creationDate}`,
      selector: (row) =>
        moment(row?.creationDate.toDate()).format("DD/MM/YYYY HH:mm:ss"),
      sortable: true,
    },
    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold mx-auto">
          Actions
        </span>
      ),
      sortable: false,
      width: "200px",

      cell: (row) => {
        return (
          <div className="flex flex-row gap-4 mx-auto">
            {/* <img
              src={eyeIcon}
              alt="show"
              className="text-gray-500 hover:text-blue-500 text-2xl  cursor-pointer"
              onClick={() => {
                setSelectedPoint(row);

                setShowPoint(true);
              }}
            />
            <img
              src={editIcon}
              alt="edit"
              className="text-gray-500 hover:text-blue-500 text-2xl  cursor-pointer"
              onClick={() => {
                setSelectedPoint(row);
                setShowFormular(true);
              }}
            /> */}
            <img
              src={deleteIcon}
              alt="delete"
              className="text-red-500 hover:text-red-800 text-2xl cursor-pointer"
              onClick={() => {
                setIsOpen(true);
                setSelectedNotification(row);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="h-full pr-1  px-5 " key="table">
      {showFormul && <NotificationForm setShowFormul={setShowFormul} />}
      {open && (
        <SuppDialog
          open={open}
          text={"Êtes-vous sûr de vouloir supprimer cette notification ?"}
          setIsOpen={setIsOpen}
          setRow={setSelectedNotification}
          onConfirme={async () => {
            await selectedNotification.delete();
            setIsOpen(false);
            setSelectedNotification(undefined);
            showAlert("La notification a étée supprimée avec succès.");
          }}
        />
      )}
      <div className="h-full w-full p-7 ">
        {loding ? (
          <div className="h-40 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="bg-white max-w-full lg:p-7 p-3  rounded-3xl  ">
            <div className="flex flex-col">
              <div className="flex items-center mb-4  justify-between">
                <h1 className="lg:text-3xl md:text-xl text-lg font-bold leading-tight text-gray-900 lg:mb-0">
                  Notifications
                </h1>

                <button
                  onClick={() => setShowFormul(true)}
                  className="bg-amber-500 text-white font-medium rounded-full pl-3 pr-1 py-2 flex flex-row items-center"
                >
                  <h1 className="pr-5">Créer une notification</h1>
                  <div className="bg-white rounded-full p-2">
                    <FiPlusSquare className=" text-amber-500 text-xl" />
                  </div>
                </button>
              </div>
              <DataTable
                columns={columns}
                noDataComponent="Aucun résultat trouvé"
                data={data}
                pagination
                paginationPerPage={10}
                customStyles={customStyles}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default NotificationTable;
