import axios from "axios";
const GOOGLE_KEY = "AIzaSyB6xJuJG9zvaK8yS9ExgJBkquDloinsKzc";
const YOUTUBE_ENDPOINT = "https://www.googleapis.com/youtube/v3/videos";
const DRIVE_ENDPOINT = "https://www.googleapis.com/drive/v3/files";
export const getDriveDuration = async (link) => {
  const id = getFileIdFromUrl(link);
  let duration = 0;

  await axios
    .get(`${DRIVE_ENDPOINT}/${id}?fields=videoMediaMetadata&key=${GOOGLE_KEY}`)
    .then((response) => {
      console.log(response);
    });

  try {
    const {
      data: { videoMediaMetadata },
    } = await axios.get(
      `${DRIVE_ENDPOINT}/${id}?fields=videoMediaMetadata&key=${GOOGLE_KEY}`
    );
    duration = convertMillisToSeconds(videoMediaMetadata?.durationMillis);
    console.log(duration);
  } catch (error) {}

  return duration;
};

export const getYoutubeDuration = async (link) => {
  console.log(link);
  const url = new URL(link);
  const params = new URLSearchParams(url.search);
  const id = params.get("v");

  let duration = 0;
  try {
    const {
      data: { items },
    } = await axios.get(
      `${YOUTUBE_ENDPOINT}?id=${id}&key=${GOOGLE_KEY}&part=contentDetails`
    );

    if (!items?.length) return;

    const contentDetails = items?.[0]?.contentDetails;
    duration = convertDurationToMinutes(contentDetails?.duration);

    return duration;
  } catch (error) {}
  return duration;
};

export const getVideoDuration = async (link) => {
  switch (true) {
    case link.includes("drive"):
      return await getDriveDuration(link);

    default:
      return await getYoutubeDuration(link);
  }
};

const convertDurationToMinutes = (durationString) => {
  const durationRegex = /PT(?:(\d+)M)?(?:(\d+)S)?/;
  const match = durationString.match(durationRegex);

  if (!match) {
    return 0;
  }

  console.log(match);

  const minutes = match[1] ? parseInt(match[1], 10) : 0;
  const seconds = match[2] ? parseInt(match[2], 10) : 0;

  const totalMinutes = minutes + seconds / 60;

  return totalMinutes;
};
const convertMillisToMinutes = (durationMillis) => {
  const minutes = durationMillis / 60000;
  return minutes;
};
const convertMillisToSeconds = (durationMillis) => {
  const seconds = durationMillis / 1000;
  return seconds;
};

const getFileIdFromUrl = (link) => {
  const fileIdRegex = /\/file\/d\/([^/]+)\//;
  const match = link.match(fileIdRegex);
  return match ? match[1] : null;
};
