import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-date-range/dist/styles.css'; // main style file range date picker
import 'react-date-range/dist/theme/default.css'; // theme css file for range date picker
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import SideBareProvider from './context/SideBareContext/SideBareProvider';
import AlertProvider from './context/AlertContext/alertProvide';
import AuthProvider from './context/AuthContext/AuthProvide';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <AlertProvider>
    <AuthProvider>
      <SideBareProvider>

        <BrowserRouter>
          <App />
        </BrowserRouter>

      </SideBareProvider>
    </AuthProvider>
  </AlertProvider >
  // </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
