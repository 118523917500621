import { baseImageURL } from "../api/axiosConfig";
import {
  createOne,
  getDocument,
  deleteOne,
  getAll,
  getDocuments,
  updateOne,
} from "../utils/database";
import { formatDate } from "../utils/date";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
export const ROLE_MANAGER = "gestionnaire";

export const ROLE_SUPER_ADMIN = "superadmin";
class Admin {
  constructor(admin) {
    this.id = admin?.id;
    this.name = admin?.name;
    this.prenom = admin?.prenom;
    this.email = admin?.email;
    this.avatar = admin?.avatar
      ? baseImageURL + admin?.avatar
      : "https://firebasestorage.googleapis.com/v0/b/girouette-3078c.appspot.com/o/users%2Fapp_icon.png?alt=media&token=839e8e93-4cae-446b-a369-c9889ab39153";
    this.is_active = admin?.is_active ?? false;
    this.role = admin?.role ?? ROLE_MANAGER;
    this.created_at = admin?.created_at ?? formatDate(new Date());
  }

  static collectionName = "admins";

  static getAllAdmins(callBack) {
    return getAll(this.collectionName, callBack);
  }

  static getAdmin(callBack) {
    return getDocument(this.collectionName, callBack);
  }

  static async create(newAdmin) {
    try {
      await createOne(this.collectionName, {
        name: newAdmin.name,
        prenom: newAdmin.prenom,
        email: newAdmin.email,
        avatar: newAdmin.avatar ?? "",
        is_active: newAdmin.is_active ?? false,
        role: newAdmin.role ?? ROLE_MANAGER,
      });
    } catch (e) {
      throw e;
    }
  }

  static getAll(querySearch) {
    // return getDocuments(this.collectionName, [
    //   where("role", "not-in", [ROLE_MANAGER, ROLE_SUPER_ADMIN]),
    //   ...querySearch,
    // ]);
    return getDocuments(this.collectionName, querySearch);
  }

  async delete() {
    await deleteOne(Admin.collectionName, this.id);
  }

  async sendResetPasswordEmail() {
    const auth = getAuth();

    return await sendPasswordResetEmail(auth, this.email);
  }

  async save() {
    await updateOne(Admin.collectionName, this.id, {
      name: this.name?.toLowerCase(),
      prenom: this.prenom,
      email: this.email,
      avatar: this.avatar ?? "",
      is_active: this.is_active ?? false,
      role: this.role ?? ROLE_MANAGER,
    });
  }
}
export default Admin;
